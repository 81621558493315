import React from 'react'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import AllWebinars from '../../components/allWebinars'
import { Button } from '../../components/global'

const W004 = () => (
  <Layout includeNotice={false}>
    <SEO title="Webinar 004: How To Troubleshoot Any Electrical Circuit on Any Vehicle (Part 2 of 2)" />
    <div className="container container-last">
      <h1>
        Webinar 004:
        <br />
        How To Troubleshoot Any Electrical Circuit on Any Vehicle (Part 2 of 2)
      </h1>
      <hr />
      <p>
        Webinar Part 2 of 2 parts covers the last three common electrical problems that could occur
        in any vehicle electrical-electronic circuit.
      </p>
      <ul>
        <li>
          How to use the "TTS" when the load is controlled by an on-board computer. How ohmmeters
          work and how to use them to find "shorts" in vehicle circuits. Mistakes testing a
          component's resistance in circuit are explained and the correct way is demonstrated.
        </li>
        <li>
          What is a "short? A logical and easy way to find a short-to-ground when you use this
          technique.
        </li>
        <li>
          What's the story on F.E.T. fuses? They can be a problem if you fail to do this first. What
          is the best way to find a short-to-voltage?
        </li>
      </ul>
      <p>
        Final points of clarification on troubleshooting the 8 ways any vehicle
        electrical-electronic circuit can fail.
      </p>
      <ul style={{ marginBottom: 0 }}>
        <li>Watch Webinar W004 Session</li>
        <li>
          Purchase a link to download the Webinar W004 recording and watch as often as you like.
          (Download link expires after 7 days)
        </li>
      </ul>
      <Button
        type="secondary"
        text="BUY NOW for $39.00"
        href="http://www.1shoppingcart.com/SecureCart/SecureCart.aspx?mid=2169DBD4-F088-4CA4-A011-56C1593411AC&pid=3390a2162ba8450ab462ce82344dda23"
      />

      <hr />
      <AllWebinars />
    </div>
  </Layout>
)

export default W004
